import moment from 'moment';

/**
 * 
 * Generates a short URL from a long URL.
 * 
 * @param {*} url 
 * @param {*} alias 
 * @returns 
 */
export const getShortUrl = async (url, alias) => {
  try {
    const response = await fetch(process.env.REACT_APP_GET_SHORT_URL,
    {
      method: "POST",
      body: JSON.stringify({
        url: url,
        alias: alias
      })
    });

    const data = await response.json();

    if(response.status === 401) {
      return {
        status: response.status,
        message: data.message
      }
    } else {
      return {
        status: response.status,
        shortUrl: data.shortUrl
      };
    }
  } catch (error) {
    console.log(error);
    return null
  }
};

/**
 * 
 * Retrieves the original URL by passing a short value
 * 
 * @param {*} shortValue 
 * @returns 
 */
export const getOriginalUrl = async (shortValue) => {
  try {
    const response = await fetch(process.env.REACT_APP_GET_ORIGINAL_URL,
    {
      method: "POST",
      body: JSON.stringify({
        shortValue: shortValue
      })
    });

    if (response.ok) {
      const data = await response.json();
      return data.originalUrl;
    } else {
      return null
    }
  } catch (error) {
    console.log(error);
    return null
  }
};

/**
 * Gets the URL statistics
 * 
 * @param {*} url 
 * @returns 
 */
export const getUrlStats = async (url) => {
  console.log("fetching data..")
  try {
    const response = await fetch(process.env.REACT_APP_GET_URL_STATS,
    {
      method: "POST",
      body: JSON.stringify({
        url: url
      })
    });

    const data = await response.json();

    if(response.status === 401) {
      return {
        status: response.status,
        message: data.message
      }
    } else {
      // If lastViewedDate = createdDate, it means it the url has never been viewed.
      if (data.lastViewedDate === data.createdDate) {
        data.lastViewedDate = "Not viewed"
      } else {
        data.lastViewedDate = moment(data.lastViewedDate).format('MM/DD/YYYY h:mm a');
      }

      data.createdDate = moment(data.createdDate).format('MM/DD/YYYY h:mm a');

      return {
        status: response.status,
        body: data
      };
    }
  } catch (error) {
    console.log(error);
    return null
  }
};