import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';


export default class About extends React.Component {

  render() {

    return (
      <div class="relative overflow-hidden">
        <div class="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24">
          <div class="mx-auto max-w-5xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span class="block text-orange-500">About Us 🙋🏻‍♂️</span></h1>
                  <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">This website has been developed as by Michael Ortiz, a Software/Platform Engineer. The site was built entirely in AWS using serverless services. Stack includes React, DynamoDB, S3, CloudFront and Terraform for IaC. Also, fun fact... this site was built from scratch in 16 hours. 🚀 😁</p>
                  <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">For questions, suggestions or any other related topic, you can email us at:</p>
                  <a href="mailto:cutmyurl@gmail.com">cutmyurl@gmail.com</a>
                  <div class="mt-10 sm:mt-12">
                  </div>
                  <Link to="/">Go Back</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
