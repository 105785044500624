import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { getUrlStats } from '../api/ApiCalls';

export default class Stats extends React.Component {

  state = {
    showStats: false,
    stats: {}
  }
  
  handleSubmit = (event) => {
    event.preventDefault()
    document.getElementById("submit").disabled = true;
    document.getElementById("submit").innerText= "Loading";

    const url = event.target.url.value;

    const validationResponse = validateRequestParams(url);

    if (validationResponse.errors) {
      alert(validationResponse.message)
      this.reset()
      return
    }

    getUrlStats(url)
    .then( result => {

      if (result.status === 200) {
        this.setState({showStats: true})
        this.setState({stats: result.body})
        document.getElementById("submit").disabled = false;
        document.getElementById("submit").innerText= "Get Statistics";
      } else {
        alert(result.body.message)
        this.reset()
      }
     }, function(error) {
      console.log("ERROR", error)
      this.reset()
     });
  }

  reset() {
    this.setState({showStats: false})
    this.setState({stats: {}})
    document.getElementById("submit").disabled = false;
    document.getElementById("submit").innerText= "Get Statistics";
  }

  render() {
    if (!this.state.showStats) {
      const defaultHtml = (

        <div class="relative overflow-hidden">
        <div class="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24">
          <div class="mx-auto max-w-5xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-5xl lg:mt-5 xl:text-5xl text-orange-500">URL Statistics 📊</h1>
                  <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">To view a link stats, enter a cutmyurl.com URL below.</p>
                  <div class="mt-10 sm:mt-12">
                    <div onSubmit={this.handleSubmit}>
                      <form noValidate>
                        <div class="sm:flex">
                          <div class="min-w-0 flex-1">
                            <input id="url" name="url" type="url" placeholder="Enter short url (ex: cutmyurl.com/AbcDeFGh)" autoCorrect="off" autoCapitalize="none" class="block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400" autoComplete="off" />
                          </div>
                          <div class="mt-3 sm:mt-0 sm:ml-3">
                            <button type="submit" id='submit' class="block w-full rounded-md bg-orange-500 py-3 px-4 font-medium text-white shadow hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2 focus:ring-offset-gray-900">Get Statistics</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <br/>
                  <Link to="/">Go Back</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      )
      return defaultHtml;
    } 
    else {

      const showShortUrlHtml = (
        <div class="relative overflow-hidden">
        <div class="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24">
          <div class="mx-auto max-w-5xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                <div class="lg:py-24">
                <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-5xl lg:mt-5 xl:text-6xl text-orange-500">URL Statistics 📊</h1>
                  <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">To view a link stats, enter a cutmyurl.com URL below.</p>
                  <div class="mt-10 sm:mt-12">
                    <div onSubmit={this.handleSubmit}>
                      <form noValidate>
                        <div class="sm:flex">
                          <div class="min-w-0 flex-1">
                            <input id="url" name="url" type="url" placeholder="Enter short url." autoCorrect="off" autoCapitalize="none" class="block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400" autoComplete="off" />
                          </div>
                          <div class="mt-3 sm:mt-0 sm:ml-3">
                            <button type="submit" id='submit' class="block w-full rounded-md bg-orange-500 py-3 px-4 font-medium text-white shadow hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2 focus:ring-offset-gray-900">Get Statistics</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <br/>
                  <Link to="/">Go Back</Link>
                  <br/>
                  <h1 class="mt-4 text-4xl ">
                    <span class="block text-orange-500">Views</span>
                  </h1>
                  <h1 class="mt-4 text-2xl ">{this.state.stats.viewsCounter}</h1>
                  <h1 class="mt-4 text-4xl ">
                    <span class="block text-orange-500">Last Viewed</span>
                  </h1>
                  <h1 class="mt-4 text-2xl ">{this.state.stats.lastViewedDate}</h1>
                  <h1 class="mt-4 text-4xl ">
                    <span class="block text-orange-500">Created On</span>
                  </h1>
                  <h1 class="mt-4 text-2xl ">{this.state.stats.createdDate}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )

      
      return showShortUrlHtml;
    }
  }
}

/**
 * 
 * Validates the URL
 * 
 * @param {*} url 
 * @returns 
 */
function validateRequestParams(url) {
  
  if (url === undefined || url === "") {
    return {
      errors: true,
      message: "Please enter a valid URL."
    }
  }

  const isValidUrl = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(url);

  if (!isValidUrl || (!url.startsWith("cutmyurl.com/") && !url.startsWith("https://cutmyurl.com/"))) {
    return {
      errors: true,
      message: "URL is not valid."
    }
  }

  return {
    errors: false,
    message: ""
  }
}