var style = {
  textAlign: "center",
  padding: "5px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "40px",
  width: "100%",
}


function Footer() {
  return (
    <div>
        <div style={style}>
          <a href="/about">About</a>
        </div>
    </div>
  )
}

export default Footer