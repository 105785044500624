import '../App.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getOriginalUrl } from '../api/ApiCalls';

var count = 0

const Redirect = () => {

  count++
  
  const { shortValue } = useParams()
 
  const validShortValueRegex = /^[A-Za-z0-9_-]{1,64}$/
  
  if (!validShortValueRegex.test(shortValue)) {
    return(
      <div className='center-screen'>
        <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
          <span class="block text-orange-500">Oops! URL Not Found.</span>
        </h1>
      </div>
    ) 
  }

  if (count === 1) {
    getOriginalUrl(shortValue)
    .then( result => {

      if (result !== null) {
        if (!result.startsWith("http")) {
          result = `https://${result}`
        }
        window.location.replace(result);
      }

    }, function(error) {
      console.log("ERROR", error)
    });
  }

  return
}

export default Redirect

