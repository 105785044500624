import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { getShortUrl } from '../api/ApiCalls';

export default class Home extends React.Component {

  state = {
    showShortUrl: false
  }
  
  handleSubmit = (event) => {
    event.preventDefault()
    
    document.getElementById("submit").disabled = true;
    document.getElementById("submit").innerText= "Generating";

    const url = event.target.url.value;
    const alias = event.target.alias.value;

    const validationResponse = validateRequestParams(url, alias);

    if (validationResponse.errors) {
      alert(validationResponse.message)
      this.reset()
      return
    }

    getShortUrl(url, alias)
    .then( result => {

      if (result.status === 200) {
        this.setState({showShortUrl: true})
        this.setState({shortUrl: result.shortUrl})
      } else {
        alert(result.message)
        this.reset()
      }
     }, function(error) {
      console.log("ERROR", error)
      this.reset()
     });
  }

  reset() {
    this.setState({showShortUrl: false})
    this.setState({shortUrl: ""})
    document.getElementById("submit").disabled = false;
    document.getElementById("submit").innerText= "Shorten";
  }

  copy(shortUrl) {

    // Copy Text
    navigator.clipboard.writeText(shortUrl);

    document.getElementById("copy-button").innerText= "Copied!";
    
    var timeLeft = 1;
    var downloadTimer = setInterval(function(){
      if(timeLeft <= 0){
        document.getElementById("copy-button").innerText= "Copy";
        clearInterval(downloadTimer);
      }
      timeLeft -= 1;
    }, 1000);
  }

  goToStats() {
    window.location.replace('/stats');
  }

  render() {
    if (!this.state.showShortUrl) {
      const defaultHtml = (

        <div class="relative overflow-hidden">
        <div class="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24">
          <div class="mx-auto max-w-5xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl text-orange-500">✂️ CUTMYURL 🔗</h1>
                  <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Generate short URL's and share them with anyone. Create customized URL's using aliases and check link statistics for free. Shortened URL's never expire.</p>
                  <div class="mt-10 sm:mt-12">
                    <div onSubmit={this.handleSubmit}>
                      <form noValidate>
                        <div class="sm:flex">
                          <div class="min-w-0 flex-1">

                            <input id="url" name="url" type="url" placeholder="Enter long URL" autoCorrect="off" autoCapitalize="none" class="block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400" autoComplete="off" />
                            
                            <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-sm xl:text-sm" style={{"text-align": "left"}}> Enter a custom alias for your URL (optional)</p>
                            <input id="alias" name="alias" type="text" placeholder="Custom alias" autoCorrect="off" autoCapitalize="none" class="block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400" autoComplete="off" />
                          </div>
                          <div class="mt-3 sm:mt-0 sm:ml-3">
                            <button type="submit" id='submit' class="block w-full rounded-md bg-orange-500 py-3 px-4 font-medium text-white shadow hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2 focus:ring-offset-gray-900">Shorten</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <br/>
                  <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" onClick={this.goToStats}>
                    Get URL Statistics
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      )
      return defaultHtml;
    } 
    else {

      const showShortUrlHtml = (
        <div class="relative overflow-hidden">
        <div class="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24">
          <div class="mx-auto max-w-5xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl">
                    <span class="block text-orange-500">Congratulations! ✅</span>
                  </h1>
                  <p class="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Your short URL is now available for use. Use the copy button to share it with anyone!</p>
                  <div class="mt-10 sm:mt-12">
                    <div class="sm:flex">
                      <div class="min-w-0 flex-1">
                        <input id="url" name="url" type="url" readOnly value={this.state.shortUrl} class="block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400" autoComplete="off" />
                      </div>
                      <div class="mt-3 sm:mt-0 sm:ml-3">
                        <button onClick={() => {
                          this.copy(this.state.shortUrl)
                          }} id='copy-button' class="block w-full rounded-md bg-orange-500 py-3 px-4 font-medium text-white shadow hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-offset-2 focus:ring-offset-gray-900">Copy</button>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <Link to="/" onClick={this.reset}>Create a New URL</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )

      
      return showShortUrlHtml;
    }
  }
}

/**
 * 
 * Validates the URL
 * 
 * @param {*} url 
 * @returns 
 */
function validateRequestParams(url, alias) {
  
  if (url === undefined || url === "") {    
    return {
      errors: true,
      message: "Please enter a URL."
    }
  }

  if (!/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(url)) {
    return {
      errors: true,
      message: "URL is not valid."
    }
  }

  if (url.toLowerCase().includes("cutmyurl.com")) {
    return {
      errors: true,
      message: "Domain not allowed."
    }
  }

  if (alias && !/^[A-Za-z0-9_-]{1,64}$/.test(alias)) {
    return {
      errors: true,
      message: "Invalid alias. Allowed characters: (A-Z a-z 0-9 _ -)"
    }
  }

  return {
    errors: false,
    message: ""
  }
}